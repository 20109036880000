









































































































































































































import moment from 'moment';
import Vue from 'vue';
import _ from 'lodash';
import { handleVideoUrl } from '~/util/common';

export default Vue.extend({
  name: 'NewsExh',
  filters: {
    dateFormat(date: string) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm');
    },
  },
  props: {
    detail: {
      // detail.pavilionInfo.picture
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // 展馆图片
    exhibitionHallList() {
      let str = this.detail.pavilionInfo.picture;
      let list = str.split(';');
      return list && list.length > 0 ? list : [];
    },
  },
  watch: {
    list(e) {
      // 过滤掉resourceUrl为空的数据
      let newList = e.filter((item: any) => item.resourceUrl);

      if (newList && newList.length > 0) {
        this.imageList = this.handleImage(newList);
        this.videoList = this.handleVideo(newList);

        // 默认展示第一个
        this.tabYearActive = `${this.imageList[0]['name']}-0`;
        this.yearActiveIndex = `${this.imageList[0]['name']}-0`;
      } else {
        // 没有数据则默认展示展馆图片
        this.imageTypeIndex = 99;
        // this.tabYearActive = "展馆图片-0",
        // this.yearActiveIndex = "展馆图片-0"
      }
    },
  },
  data(): any {
    return {
      firstIndex: 0, // 0 图片, 1 视频
      activeIndex: 0,
      // 图片
      imageList: [],
      // 视频
      videoList: [],
      imageTypeIndex: 0, // 图片类型索引
      tabYearActive: '现场图片-0',
      yearActiveIndex: '现场图片-0', // 年份索引
      // 视频
      videoYearActive: '',
      videoYearIndex: 0,
    };
  },
  methods: {
    handleVideoUrl,
    handleFirstTab(i: number) {
      this.firstIndex = i;

      if (i == 0) {
        // this.tabYearActive = '现场图片-0'
        // this.yearActiveIndex = '现场图片-0'
      }
      if (i == 1) {
        this.videoYearActive = `${this.videoList[0]['yearName']}-0`; //'视频-0'
        this.videoYearIndex = `${this.videoList[0]['yearName']}-0`;
      }
    },
    handleTabType(name: string, i: number) {
      this.imageTypeIndex = i;
      this.tabYearActive = `${name}-0`;
      this.yearActiveIndex = `${name}-0`;
    },
    handleTabYear(a: string, b: number) {
      if (this.firstIndex == 0) {
        this.yearActiveIndex = `${a}-${b}`;
        this.tabYearActive = `${a}-${b}`;
      }
      if (this.firstIndex == 1) {
        this.videoYearIndex = `${a}-${b}`;
      }
    },
    // 处理图片数据 (不包含视频 resourceType != 4)
    handleImage(list: any) {
      let result: any = [
        { name: '现场图片', resourceType: 2, active: 0, children: [] },
        { name: '展位图', resourceType: 1, active: 0, children: [] },
      ];
      let imgList = list.filter((i: any) => i.resourceType != 4);
      // 2 现场图片, 1 展位图
      result.map((item: any) => {
        let year = this.getYearList(imgList, item.resourceType);
        item.children.push(...year);
      });
      // imgList.map((i: any) => {
      //   // i["picList"] = i.resourceUrl.split(",")
      //   if (!yearList.every((y: any) => y.yearName == i.resourceYear)) {
      //     yearList.push({
      //       yearName: i.resourceYear,
      //       children: []
      //     })
      //   }
      //   // if (i.resourceType == 2) result[0].children.push(i)
      //   // if (i.resourceType == 1) result[1].children.push(i)
      // })
      // 过滤空数据
      result = result.filter((item: any) => item.children.length != 0);
      return result;
    },
    // 处理视频数据 (不包含图片 resourceType == 4)
    handleVideo(list: any) {
      // let result: any = [
      //   { name: "现场图片", resourceType: 2, active: 0, children: [] },
      //   { name: "展位图", resourceType: 1, active: 0, children: [] }
      // ]
      let imgList = list.filter((i: any) => i.resourceType == 4);
      let yearList = imgList.length > 0 ? this.getYearList2(imgList, 4) : [];

      // 2 现场图片, 1 展位图
      // result.map((item:any) => {
      //   let year = this.getYearList(imgList,4)
      //   item.children.push(...year)
      // })
      // imgList.map((i: any) => {
      //   // i["picList"] = i.resourceUrl.split(",")

      //   if (!yearList.every((y: any) => y.yearName == i.resourceYear)) {
      //     yearList.push({
      //       yearName: i.resourceYear,
      //       children: []
      //     })
      //   }
      //   // if (i.resourceType == 2) result[0].children.push(i)
      //   // if (i.resourceType == 1) result[1].children.push(i)
      // })
      // console.log("年份", yearList)
      return yearList;
    },
    // 获取年份的子级(list 列表, resourceType 类型)
    getYearList(list: any, resourceType: number) {
      let result: any = [];
      list.map((item: any) => {
        item['picList'] = item.resourceUrl.split(',');
        if (
          item.resourceType == resourceType &&
          !result.some((y: any) => y.yearName == item.resourceYear)
        ) {
          result.push({
            yearName: item.resourceYear,
            resourceYear: item.resourceYear,
            resourceType: item.resourceType,
            children: [],
          });
        }
      });
      let sortList = result;
      // 年份降序排序
      if (result.length > 0) {
        sortList = result.sort(
          (a: any, b: any) => b.resourceYear - a.resourceYear
        );
        // 如果第一个是往届,则把往届挪到最后一个位置
        if (sortList[0]['resourceYear'].includes('往届'))
          sortList.push(sortList.shift());
      }

      sortList.map((item: any) => {
        let filterList = list.filter(
          (i: any) =>
            i.resourceType == item.resourceType &&
            i.resourceYear == item.yearName
        );
        item.children = filterList;
      });

      return sortList;
    },
    // 获取年份的子级(list 列表, resourceType 类型)
    getYearList2(list: any, resourceType: number) {
      let result: any = [];
      list.map((item: any) => {
        item['picList'] = item.resourceUrl.split(',');
        if (
          item.resourceType == resourceType &&
          !result.some((y: any) => y.yearName == item.resourceYear)
        ) {
          result.push({
            yearName: item.resourceYear,
            resourceYear: item.resourceYear,
            resourceType: item.resourceType,
            children: [],
          });
        }
      });

      // 年份降序排序
      let sortList = result.sort(
        (a: any, b: any) => b.resourceYear - a.resourceYear
      );
      // 如果第一个是往届,则把往届挪到最后一个位置
      if (sortList[0]['resourceYear'].includes('往届'))
        sortList.push(sortList.shift());

      sortList.map((item: any) => {
        let filterList = list.filter(
          (i: any) =>
            i.resourceType == item.resourceType &&
            i.resourceYear == item.yearName
        );
        item.children = filterList;
      });

      return sortList;
    },
  },
});
